import errors from '@/mixin/errors'
import CardHeadNavigation from '../components/CardNavigation'
import SubmitBtn from '@/components/SubmitBtn'
import DownloadContract from '../components/DownloadContract'
import table from '@/mixin/table'
import { isEqual } from 'lodash/lang'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import { mapGetters } from 'vuex'
import CSelectSearch from '@/components/CSelectSearch'
import ApiService from '@/services/ApiService'
import useTaskSignersAndAssigners from '@/composables/useTaskSignersAndAssigners'
import Gate from '@/services/Gate/Gate'

export default {
  name: 'TaskSendSignature',
  // TODO replace to Gate and middleware
  async beforeRouteEnter(to, from, next) {
    await ApiService.api.tasks
      .getTask({params: {task_id: to.params.task_id}})
      .then((res) => {
        if (res.data.data?.contract?.isApproved !== true || res.data.data.is_external) {
          next({name: 'ProjectTasks', params: {id: to.params.id}})
        } else {
          next()
        }
      })
      .catch(() => {
        next({name: 'ProjectTasks', params: {id: to.params.id}})
      })
  },
  async beforeRouteLeave() {
    if (this.isEditForm) {
      await this.$refs.confirmDialog
        .confirm({
          text: this.$t('messages.unsaved'),
          confirmText: 'Leave',
          cancelText: 'Cancel',
          reverse: true,
        })
        .then((res) => {
          if (res) return true
          else {
            return Promise.reject(false)
          }
        })
    } else {
      return true
    }
  },
  mixins: [errors, table],
  components: {
    CardHeadNavigation,
    SubmitBtn,
    DownloadContract,
    ConfirmDialog,
    CSelectSearch,
  },
  inject: ['toast'],
  setup() {
    const { usersList, getUsers, loadingUsers } = useTaskSignersAndAssigners()
    return { usersList, getUsers, loadingUsers }
  },
  mounted() {
    this.fetchData()
    this.fetchDataInfo()
  },
  data() {
    return {
      origin: {},
      task: {},
      receiving_signer_id: null,
      sending_signer_id: null,
      contract_id: null,
      tableVis: true,
      tasks: [],
      receivingUsersList: [],
      myUsersList: [],
      fields: [
        // { key: 'line', label: '', sorter: false, _style: 'max-width: 30px; width: 30px' },
        {
          key: 'name',
          label: 'Task Name',
          click: (item) => this.goToView(item),
        },
        {
          key: 'task_id',
          label: 'Task ID',
          click: (item) => this.goToView(item),
        },
        {
          key: 'due_date',
          label: 'Due Date',
          format: (value) => (value ? this.$BtoF({ value }) : 'N/A'),
        },
        { key: 'status', label: 'Status' },
        { key: 'priority', label: 'Priority' },
        { key: 'task_priority', label: 'Task (Primary, Secondary)' },
        {
          key: 'is_internal',
          label: 'Internal/External',
          format: (value) => (value ? 'Internal' : 'External'),
        },
      ],
      sending: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),

    isEditForm() {
      return !isEqual(
        {
          receiving_signer_id: this.receiving_signer_id,
          sending_signer_id: this.sending_signer_id,
        },
        this.origin,
      )
    },
    routerAfterChange() {
      return { name: 'ProjectTasks', params: { id: this.selectProject } }
    },
    receivingUser() {
      const find = this.usersList.internal_signers.find(
        (item) => item.data.id === this.sending_signer_id,
      )
      return find ? find.data : null
    },
    sendingUser() {
      const find = this.usersList.external_signers.find(
        (item) => item.data.id === this.receiving_signer_id,
      )
      return find ? find.data : null
    },
  },
  methods: {
    goToView(item) {
      if (item.type === 'Request documents') {
        this.$router.push({
          name: 'TaskRequest',
          params: { id: this.$route.params.id, task_id: item.id },
        })
      } else if (item.type === 'Contracts') {
        this.$router.push({
          name: 'TaskContracts',
          params: { id: this.$route.params.id, task_id: item.id },
        })
      } else if (item.type === 'Acknowledge') {
        this.$router.push({
          name: 'TaskAcknowledge',
          params: { id: this.$route.params.id, task_id: item.id },
        })
      }
    },
    fetchData() {
      this.$http.library
        .relatedTasks({
          params: {
            task_id: this.$route.params.task_id,
            sort_field: (this.sort && this.sort.column) || 'task_id',
            sort_direction: (this.sort && this.sort.state) || 'desc',
          },
        })
        .then((res) => {
          this.tasks = res.data.data
        })
    },
    fetchDataInfo() {
      this.loadingUsers = true
      this.$http.tasks
        .getTask({ params: { task_id: this.$route.params.task_id } })
        .then((res) => {
          if (
            res.data.data?.contract?.isApproved !== true ||
            res.data.data.is_external
          ) {
            this.$router.push({
              name: 'ProjectTasks',
              params: {id: this.$route.params.id},
            })
          }

          this.task = res.data.data
          this.contract_id = res.data.data.contract?.id
          this.receiving_signer_id = res.data.data.external_signer?.id
          this.sending_signer_id = res.data.data.internal_signer?.id

          this.origin.receiving_signer_id = res.data.data.external_signer?.id
          this.origin.sending_signer_id = res.data.data.internal_signer?.id

          this.getUsers(this.task.scope_id)
        })
    },
    async handleCancel() {
      if (this.isEditForm) {
        await this.$refs.confirmDialog
          .confirm({
            text: this.$t('messages.unsaved'),
            cancelText: 'Cancel',
            confirmText: 'OK',
            reverse: true,
          })
          .then((res) => {
            if (res) {
              this.cancel()
            }
          })
      } else {
        this.cancel()
      }
    },
    cancel() {
      this.fetchDataInfo()
    },
    async handleSave() {
      await this.$refs.confirmDialog
        .confirm({
          text: 'Are you sure you want to send the contract for signature?',
          info: ' It will not be possible to edit the contract after this action.',
          confirmText: 'Confirm',
          cancelText: 'Cancel',
          reverse: true,
        })
        .then((res) => {
          if (res) this.save()
        })
    },
    save() {
      this.sending = true

      this.$http.tasks
        .setContractSigners({
          contract_id: this.contract_id,
          sending_signer_id: this.sending_signer_id,
          receiving_signer_id: this.receiving_signer_id,
        })
        .then(async () => {
          this.toast('success', 'The contract has been sent to the signers')
          this.origin.receiving_signer_id = this.receiving_signer_id
          this.origin.sending_signer_id = this.sending_signer_id

          this.$store.commit('setTaskEntity', {})
          await Gate.can('signView', 'task', this.$route.params.task_id).then(async (res) => {
              if (res) {
                return await this.$router.push({
                  name: 'TaskSign',
                  params: { id: this.$route.params.id, task_id: this.$route.params.task_id },
                })
              }
            })
        })
        .catch(({ response }) => {
          this.toast('error', response.data.message)
        })
        .finally(() => {
          this.sending = false
        })
    },
  },
}
