<template>
  <div class="taskSendSignature">
    <ConfirmDialog ref="confirmDialog" />

    <DownloadContract v-if="task.contract" :contract-name="task.contract.name" :task-id="task.id" />
    <CBadge
      v-if="task.is_main"
      style="font-size: 10px"
      color="primary"
      class="ml-2"
      >Primary</CBadge
    >
    <CCard class="mb-4 customCardTab">
      <card-head-navigation v-if="task.id" :task="task" />

      <CCardBody>
        <CCol>
          <h2 class="taskSendSignature__title mb-4">Signers</h2>
        </CCol>
        <CCard class="mb-4">
          <CCardBody>
            <CRow>
              <CCol sm="12" md="6">
                <CRow>
                  <CCol sm="12" md="6" class="taskSendSignature__type mb-2">
                    Receiving Company Signer
                  </CCol>
                  <CCol sm="12" md="6" class="mb-2">
                    <CSelectSearch
                      v-model="receiving_signer_id"
                      :options="usersList.external_signers"
                      placeholder="Select User"
                      style="width: 100%"
                      :error="errors.receiving_signer_id"
                      :loading="loadingUsers"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="12" md="6">
                <CRow class="taskSendSignature__row">
                  <CCol cols="6">Company</CCol>
                  <CCol cols="6" class="taskSendSignature__row-value ellipsis">
                    {{ sendingUser?.company_name || 'N/A' }}
                  </CCol>
                </CRow>
                <CRow class="taskSendSignature__row">
                  <CCol cols="6">Job Title</CCol>
                  <CCol cols="6" class="taskSendSignature__row-value ellipsis">
                    {{ sendingUser?.job_title || 'N/A' }}
                  </CCol>
                </CRow>
                <CRow class="taskSendSignature__row">
                  <CCol cols="6">Email</CCol>
                  <CCol cols="6" class="taskSendSignature__row-value ellipsis">
                    {{ sendingUser?.email || 'N/A' }}
                  </CCol>
                </CRow>
                <CRow class="taskSendSignature__row">
                  <CCol cols="6">Role</CCol>
                  <CCol cols="6" class="taskSendSignature__row-value ellipsis">
                    {{ sendingUser?.company_type?.capitalize() || 'N/A' }}
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard class="mb-4">
          <CCardBody>
            <CRow>
              <CCol sm="12" md="6">
                <CRow>
                  <CCol sm="12" md="6" class="taskSendSignature__type mb-2">
                    Sending Company Signer
                  </CCol>
                  <CCol sm="12" md="6" class="mb-2">
                    <CSelectSearch
                      v-model="sending_signer_id"
                      :options="usersList.internal_signers"
                      placeholder="Select User"
                      style="width: 100%"
                      :error="errors.sending_signer_id"
                      :loading="loadingUsers"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="12" md="6">
                <CRow class="taskSendSignature__row">
                  <CCol cols="6">Company</CCol>
                  <CCol cols="6" class="taskSendSignature__row-value ellipsis">
                    {{ receivingUser?.company_name || 'N/A' }}
                  </CCol>
                </CRow>
                <CRow class="taskSendSignature__row">
                  <CCol cols="6">Job Title</CCol>
                  <CCol cols="6" class="taskSendSignature__row-value ellipsis">
                    {{ receivingUser?.job_title || 'N/A' }}
                  </CCol>
                </CRow>
                <CRow class="taskSendSignature__row">
                  <CCol cols="6">Email</CCol>
                  <CCol cols="6" class="taskSendSignature__row-value ellipsis">
                    {{ receivingUser?.email || 'N/A' }}
                  </CCol>
                </CRow>
                <CRow class="taskSendSignature__row">
                  <CCol cols="6">Role</CCol>
                  <CCol cols="6" class="taskSendSignature__row-value ellipsis">
                    {{ receivingUser?.company_type?.capitalize() || 'N/A' }}
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CRow>
          <CCol class="d-flex align-items-center flex-wrap pt-4 pb-4">
            <CButton
              :loading="sending"
              color="primary"
              class="m-2 ml-0 d-flex align-items-center justify-content-center btn-send"
              @click="handleSave"
            >
              <img src="@/assets/images/send.png" class="m-2 mt-0 mb-0" />
              Send For Signatures
            </CButton>
            <CButton
              color="primary"
              variant="outline"
              class="m-2 ml-0 btn-cancel"
              @click="handleCancel"
            >
              Cancel
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard class="mb-4">
      <CCardBody>
        <CCol>
          <h2 class="taskSendSignature__title mb-4 d-flex align-items-center">
            Tasks related with current task
            <img
              src="@/assets/arrow_down.svg"
              class="taskSendSignature-arrow-table ml-2"
              :style="`transform: rotate(${tableVis ? 180 : 0}deg)`"
              @click="tableVis = !tableVis"
            />
          </h2>
        </CCol>
        <SmartTable
          v-if="tableVis"
          :items="tasks"
          :columns="fields"
          :loading="loadingUsers"
          :sorter-value="sort"
          :clear-text="'No Data yet'"
          infinity
          class="mb-4"
          :selected-line="(item) => item.is_current"
          @sorter-change="onSort"
        >
          <template #name="{ item }">
            <div class="taskSendSignature-line">
              <template v-if="item._id">
                <div
                  class="taskSendSignature-line-line"
                  :class="{ last: item._id === tasks.length - 1 }"
                />
                <div class="taskSendSignature-line-center" />
              </template>
              <template v-else>
                <img src="@/assets/crown.svg" />
              </template>
            </div>

            <a style="text-decoration: underline; cursor: pointer">{{
              item.name || 'N/A'
            }}</a>
          </template>
        </SmartTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
