// import taskStatuses from '@/utils/constants/taskStatuses'
// import { useRoute, useRouter } from 'vue-router'

export const navigationGuard = (task) => {
  // const route = useRoute()
  // const router = useRouter()
  // if (task && route) {
  //   let isPendingSignature = [
  //     taskStatuses.CONTRACT_PENDING_EXTERNAL_SIGNATURE,
  //     taskStatuses.CONTRACT_PENDING_INTERNAL_SIGNATURE,
  //   ].includes(task.status)
  //
  //   if (!isPendingSignature && route.name === 'TaskSign') {
  //     router.replace({name: 'ProjectTasks', params: route.params})
  //   }
  //   if (isPendingSignature && ['TaskContracts', 'TaskSignature'].includes(route.name)){
  //     router.replace({ name: 'TaskSign', params: route.params })
  //   }
  //   if (task.status === taskStatuses.CONTRACT_FULLY_EXECUTED && route.name !== 'TaskFinish'){
  //     router.replace({ name : 'TaskFinish', params: route.params})
  //   }
  // }
}
