<template>
  <span
    v-if="contractName && taskId"
    :class="classes"
    style="text-decoration: underline; cursor: pointer"
    @click="downloadDocument()"
  >
    {{ $slots.default ? '' : contractName }}
    <slot></slot>
  </span>
  <span v-else :class="classes"> 'N/A' </span>
</template>

<script>
import { downloadFile } from '@/utils/files'
import contractContentType from '@/utils/constants/tasks/taskContractContentTypeDownload'

export default {
  name: 'DownloadContract',
  props: {
    contractName: { type: String, default: 'content' },
    contentType: { type: String, default: contractContentType.CONTENT },
    extension: { type: String, default: 'pdf' },
    taskId: { type: Number, default: undefined, required: true },
    classes: { type: [String, Array, Object], default: '' },
  },
  methods: {
    downloadDocument() {
      this.$http.common
        .downloadSingleDocument({
          type: 'task',
          task_id: this.taskId,
          content_type: this.contentType,
        })
        .then((response) => {
          downloadFile(response, this.contractName + '.' + this.extension)
        })
    },
  },
}
</script>

<style scoped></style>
