<template>
    <CNav variant="tabs" class="card-header-tabs mobile-hide">
        <CNavItem v-for="(item, key) in tabItems" :key="key" @click="$emit('update:modelValue', item.name)">
            <CNavLink class="navigationLink" :class="{ 'navigationLink--active': item.active, }" :active="item.active"
                      :disabled="item.disabled" @click="$router.push(item.route)">
                {{ item.name }}
            </CNavLink>
        </CNavItem>
    </CNav>

    <CRow :xs="{ gutterY: 3 }" class="justify-content-center w-100 text-center pc-hide test">
        <CCol v-for="(item, key) in tabItems" :key="key" @click="$emit('update:modelValue', item.name)">
            <CButton v-if="!item.active" color="primary" variant="ghost" class="w-75" :disabled="item.disabled"
                     @click="$router.push(item.route)">
                {{ item.name }}
            </CButton>
            <CButton v-else color="secondary" variant="outline" class="w-75">
                {{ item.name }}
            </CButton>
        </CCol>
    </CRow>
</template>

<script>
import {hasRoutePermission} from '@/utils/helper'

/**
 * Array of objects
 * links: [
 *  {
 *    name: String                  title for User
 *    disabled: Boolean             is disabled
 *    hidden: Boolean                 hide element
 *    permissions: ['permission']   control the page permissions
 *    route: {
 *      name: String                route name
 *      params: Object              route params
 *    }
 *  }
 * ]
 */
export default {
    name: 'Navigation',
    props: {
        items: {type: Array, default: []},
        modelValue: {type: String, default: ''},
    },
    emits: ['update:modelValue'],
    computed: {
        tabItems() {
            return this.items
                .filter((el) => this.checkPermission(el) && el.hidden !== true)
                .map((item) => {
                    item.active = this.isActive(item)
                    return item
                })
        },
    },
    methods: {
        checkPermission(route) {
            return hasRoutePermission(route)
                ? this.authUser.canAll(route.permissions)
                : true
        },
        isActive(item) {
            let checkRoute =
                typeof item.route === 'object' && item.route.name === this.$route.name
            let checkActive = !!this.modelValue && this.modelValue === item.name
            return checkActive ? checkActive : checkRoute
        },
    },
}
</script>

<style scoped lang="scss">
.navigationLink {
    $active-color: #303c54;

    cursor: pointer;
    color: #005d9d !important;

    &--active {
        color: $active-color !important;
    }
}
</style>
