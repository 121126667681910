import { ref } from 'vue'
import ApiService from '@/services/ApiService'

const useTaskSignersAndAssigners = () => {
  const usersList = ref({
    internal_assigners: [],
    external_assigners: [],
    internal_signers: [],
    external_signers: [],
  })

  const loadingUsers = ref(false)

  async function getUsers(scopeId) {
    loadingUsers.value = true
    return await ApiService.api.common
      .fetchPageData({
        params: { slug: 'task_details', scope_id: scopeId },
      })
      .then(({ data }) => {
        Object.keys(this.usersList).forEach((key) => {
          usersList.value[key] = data.data[key].map((i) => ({
            value: i.id,
            label: i.full_name,
            data: i,
          }))
        })
      })
      .catch()
      .finally(() => (loadingUsers.value = false))
  }

  return {
    loadingUsers,
    usersList,
    getUsers,
  }
}
export default useTaskSignersAndAssigners
